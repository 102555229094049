/* src/HomePage.css */

.image-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-carousel img {
  max-width: 100%;
  max-height: 100%;
  transition: opacity 1s ease-in-out;
}

@media (max-width: 600px) {
  .MuiTypography-h2 {
    font-size: 2rem;
  }

  .MuiTypography-h5 {
    font-size: 1.25rem;
  }

  .MuiPaper-root {
    padding: 1rem;
  }
}